/* Fireworks.css */
.fireworks-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Ensures the fireworks don't interfere with clicking */
    overflow: hidden;
    z-index: 1000;
}

.firework {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    pointer-events: none;
    animation: explode 1s ease-out forwards;
}

@keyframes explode {
    0% {
        transform: scale(0.5);
        opacity: 1;
    }
    50% {
        transform: scale(2);
        opacity: 0.7;
    }
    100% {
        transform: scale(3);
        opacity: 0;
    }
}