/* CustomButton.css */

body, html {
    height: 100%;
    margin: 0;
  }

.custom-button {
    padding: 20px 35px;
    margin: 30px 300px 0px 0px;
    width: 250px;
    font-size: 17px;
    font-weight: 100;
    /* color: #fff; */
    /* background-color: transparent;  */
    /* border: 5px solid; */
    /* border-image: linear-gradient(45deg, red, purple, pink, blue) 1;  */
    /* border-top-left-radius: 20% !important; */
    color: white;
    background-color: rgb(26, 20, 28);
    cursor: pointer;
    transition: all 0.4s ease;
    border: 0;
    border-radius: 3.5%;
  }
  
.custom-button:hover {
    /* border-image: linear-gradient(45deg, rgb(249, 249, 249), red) 1;  */
    font-size: 17.5px;
}
  