h1 {
  color: white;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 55px;
}

h4 {
  display: inline-block;
  color: white;
  width: 70%;
  font-family: "Rubik", sans-serif;
  font-weight: 300;
}

.App {
  text-align: center;
}


/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50%;
  width: 50%;
  padding-left: 10%;
  padding-top: 20%;
  text-align: left;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  h1 {
    font-size: 45px;
    width: 100%;
  }
  .container {
    padding-top: 50%;
    width: 80%;
    padding-left: 10%;
    text-align: center;
  }
  h4 {
    width: 100%;
    order: 3;
  }
  .custom-button {
    order: 2;
    margin: 30px 200px 50px 45px !important;
    /* background-color: linear-gradient(45deg, #363333, #000000) !important; */
    /* border: 0px !important; */
  }
}