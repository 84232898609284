/* Navbar.css */

.navbar {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    background-color: transparent; /* Transparent background */
    display: flex;
    justify-content: space-between; /* Align to the right */
    padding: 20px 50px 0 0;
    box-sizing: border-box;
    z-index: 1000;
  }
  
  .nav-list {
    list-style: none;
    display: flex;
    gap: 30px;
    margin: 0;
    padding: 0;
  }
  
  .nav-item a {
    text-decoration: none;
    font-size: 18px;
    color: #fff; /* Initial color */
    transition: color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .nav-item a:hover {
    color: #d74ed9; /* Color on hover (e.g., tomato) */
    text-shadow: 2px white;
  }

.logo {
  margin-right: 20px;
  margin-top: -60px;
  margin-left: -5px; 
}
  
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: 0.4s;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .nav-list {
      position: fixed;
      top: 0px;
      right: -100%;
      width: 100%;
      height: 100vh;
      background-image: url('../../images/mobile-background.jpg');
      background-size: cover;
      background-attachment: fixed;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
      transition: all 0.5s ease;
  }
  .nav-list.open {
      right: 0;
  }
  .hamburger {
    display: flex;
    z-index: 1;
    padding-top: 13px;
    padding-right: 13px;
  }
  .navbar {
    padding: 15px 25px 0 0;
  }
  .logo {
    z-index: 1;
  }

}